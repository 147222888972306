const initState = {
  awsUser: null,
  awsUserRole: null,
  availTenants: {},
  currentModuleId: null,
  currentSubsystemId: null,
  currentSystemId: 'listView',
  currentTenantId: null,
  systems: {},
  modules: null,
  positions: null,
  tripReports: null,
  users: {},
  uiBusy: 0,
  errors: [],
  offsetHeight: 0,
};

export default function(state = initState, action) {
  switch (action.type) {
    case 'SET_AWS_USER':
      return {
        ...state,
        awsUser: action.data,
        awsUserRole: action.data?.attributes?.['custom:role'],
      };

    case 'SET_UI_BUSY':
      return {
        ...state,
        uiBusy: action.data,
      };

    case 'SET_AVAIL_TENANTS':
      return {
        ...state,
        availTenants: action.data,
      };

    case 'SET_AVAIL_TENANT':
      return {
        ...state,
        availTenants: {
          ...state.availTenants,
          [action.data.tenant_id]: action.data,
        },
      };

    case 'REMOVE_AVAIL_TENANT':
    const newAvailTenants = {...state.availTenants};
    delete newAvailTenants[action.data];

      return {
        ...state,
        availTenants: newAvailTenants,
      };

    case 'SET_SYSTEMS':
      return {
        ...state,
        systems: action.data,
      };

    case 'SET_SYSTEM':
      return {
        ...state,
        systems: {
          ...state.systems,
          [action.data.system_id]: action.data,
        },
      };

    case 'REMOVE_SYSTEM':
      const newSystems = {...state.systems}
      delete newSystems[action.data];
      return {
        ...state,
        systems: newSystems,
      };

    case 'UPDATE_CURRENT_SYSTEM':
      return {
        ...state,
        currentSystemId: action.data,
      };

    case 'SET_CURRENT_TENANT':
      return {
        ...state,
        currentTenantId: action.currentTenantId,
      };

    case 'SET_USERS':
      return {
        ...state,
        users: action.data
      };

    case 'SET_USER':
      return {
        ...state,
        users: {
          ...state.users,
          [action.data.username]: action.data,
        }
      };

    case 'REMOVE_USER':
      const newUsers = {...state.users};
      delete newUsers[action.data];

      return {
        ...state,
        users: newUsers,
      };

    case 'SET_CURRENT_SUBSYSTEM_ID':
      return {
        ...state,
        currentSubsystemId: action.data,
      };

    case 'SET_CURRENT_MODULE_ID':
      return {
        ...state,
        currentModuleId: action.data,
      };

    case 'SET_MODULES':
      return {
        ...state,
        modules: action.data,
      };

    case 'SET_MODULE':
     return {
      ...state,
      modules: {
        ...state.modules,
        [action.data.module_id]: action.data,
      },
    };

    case 'REMOVE_MODULE':
      const newModules = {};
      Object.values(state.modules).forEach(currentModule => {
        if (currentModule.module_id !== action.data) {
          newModules[currentModule.module_id] = currentModule;
        }
      });
      return {
        ...state,
        modules: newModules,
      };

    case 'SET_POSITIONS':
      return {
        ...state,
        positions: action.data,
      };

    case 'SET_POSITION':
      return {
        ...state,
        positions: {
          ...state.positions,
          [action.data.position_id]: action.data,
        },
      };

    case 'REMOVE_POSITION':
      const newPositions = {};
      Object.values(state.positions).forEach(currentPosition => {
        if (currentPosition.position_id !== action.data) {
          newPositions[currentPosition.position_id] = currentPosition;
        }
      });
      return {
        ...state,
        positions: newPositions,
      };

    case 'SET_TRIP_REPORTS':
      return {
        ...state,
        tripReports: action.data,
      };


    case 'ERRORS_ADD':
      // Append the new error
      return {
        ...state,
        errors: [
          ...state.errors,
          action.error
        ],
      };
    case 'ERRORS_REMOVE':
      // Remove the first error
      return {
        ...state,
        errors: state.errors.slice(1),
      };
    case 'ERRORS_CLEAR':
      // Purge the list
      return {
        ...state,
        errors: [],
      };

    case 'UPDATE_OFFSET_HEIGHT':
      return {
        ...state,
        offsetHeight: action.data,
      };

    default:
      return state;
  }
}

import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import { topHeader } from '../styles/commonStyles';

const LoadPage = () => {
  return (
    <Grid container direction="column" justify="center" alignItems="center" style={{height: '40vh'}}>
      <Grid item>
        <CircularProgress size={80} style={topHeader} />
      </Grid>
    </Grid>
  );
}

export default LoadPage

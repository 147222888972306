// Comparator to sort items by the "sort" property
export const BY_SORT_VALUE = (a,b) => {
  return (a.sort || 0) - (b.sort || 0);
}

// Comparator to sort items by the "name" property
export const BY_NAME = (a,b) => {
  return (a.name || '').localeCompare(b.name || '');
}

// Create comparator to sort by string values
export const byString = (key) => (a,b) => {
  return (a[key] || '').localeCompare(b[key] || '');
}

// Create comparator to sort by number values
export const byNumber = (key) => (a,b) => {
  return (a[key] || 0) - (b[key] || 0);
}

export default function(items) {
  if (items && items.length) {
    // Sort in place
    items.sort(BY_SORT_VALUE);
  }

  // Return the array as well for chaining calls
  return items;
}

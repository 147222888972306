import React from 'react';
import { connect } from 'react-redux';
import { updateCurrentSystemId, updateOffsetHeight } from '../../reduxElements/actions';

import { fetchStatusBySystem } from '../../remoting';

import Auth from '@aws-amplify/auth';

import { withRouter } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
// import Drawer from '@material-ui/core/Drawer';
// import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import CheckIcon from '@material-ui/icons/Check';
import WarnIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import DoubleArrow from '@material-ui/icons/DoubleArrow';

import bwLogo from '../../images/KSS_Logo_WHT.svg';

import { withStyles } from '@material-ui/core/styles';
import { warnColor, severeAlertColor } from '../../styles/commonStyles';
import { BY_SORT_VALUE, BY_NAME, byNumber } from '../../lib/sortItems';

const style = (theme) => ({
  appBar: {
    backgroundColor: 'black',
    color: 'white',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  appBarLink: {
    marginLeft: theme.spacing(3),
  },
  adminToggleLink: {
    fontWeight: 800,
  },
  selectorNavBar: {
    backgroundColor: theme.palette.background.default,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    '& .MuiSelect-root' : {
      minWidth: '200px'
    },
    '& > * > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  contextNavBar: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    '& button' : {
      minWidth: '130px',
    },
  },
  contextTabScrollButton: {
    // color: theme.palette.primary.light,
  },
  navFlex: {
    justifyContent: 'space-between',
  },
  navIndicator: {
    backgroundColor: '#00AF9D',
    height: theme.spacing(0.5),
  },
  regArrow: {
    transition: `transform ${theme.transitions.easing.easeInOut} ${theme.transitions.duration.standard/1000}s`,
  },
  rotatedArrow: {
    transition: `all ${theme.transitions.easing.easeInOut} ${theme.transitions.duration.standard/1000}s`,
    transform: 'rotate(90deg)',
  },
  rowTab: {
    flexDirection: 'row',
  },
});

class TopNavigation extends React.Component {
  intervalId = null;

  state = {
    alertsOpen: false,
    alerts: [],
    liveDataMenuOpen: false,
    hmiMenuOpen: false,
    influentMenuOpen:false,
    membraneMenuOpen: false,
    selectorOffset: 0,
    contextOffset: 0,
  }

  componentDidMount() {
    const { checkAlerts, getContainerOffset, getContextOffset, getSelectorOffset, handleResize } = this;
    const { updateOffsetHeight } = this.props;

    checkAlerts();

    // Periodically check for alerts
    this.intervalId = setInterval(checkAlerts, 60000);

    // set offset for navbars
    const currentContainerOffset = getContainerOffset();
    updateOffsetHeight(currentContainerOffset);
    this.setState({
      selectorOffset: getSelectorOffset(),
      contextOffset: getContextOffset(),
    });

    // listen for window resize event, to set offset for navbars
    window.addEventListener('resize', handleResize)
  }

  componentDidUpdate(prevProps) {
    const { checkAlerts, handleResize, intervalId } = this;
    const { systems } = this.props;

    if (systems !== prevProps.systems) {
      clearInterval(intervalId);
      checkAlerts();
      this.intervalId = setInterval(checkAlerts, 60000);
    }

    // Check if AppBars need to be repositioned and container spacer height set.
    handleResize();
  }

  componentWillUnmount() {
    // Stop checking for alerts!
    clearInterval(this.intervalId);
  }

  checkAlerts = async () => {
    const { currentTenantId, systems, fetchStatusBySystem } = this.props;

    const allStatusPromises = Object.keys(systems)
    .map(currentSystemId => fetchStatusBySystem(currentTenantId, currentSystemId));

    const allSystemStatuses = await Promise.all(allStatusPromises);

    let alerts = [];
    allSystemStatuses.forEach(currentSystem => {
      if (currentSystem) {
        alerts = alerts.concat(currentSystem.otherAlarms);
        currentSystem.subsystems
        .forEach(currentSubsystem => {
          alerts = alerts.concat(currentSubsystem.alarms);
        });
      }
    });
    alerts = alerts.sort(byNumber('level')).reverse();

    // check for race condition
    if (currentTenantId === this.props.currentTenantId) {
      this.setState({ alerts });
    }
  }

  getContextTabValue = () => {
    const { awsUserRole, history } = this.props;

    let contextTabValue = '';
    // what to return if in admin ui
    if (history.location.pathname.search(/\/admin/) > -1) {
      contextTabValue = 'admin';

      // tab values for standard customer
    } else if (awsUserRole === 'customer') {
      if (history.location.pathname.search(/\/tenant\/\S*\/documents$/) > -1) {
        contextTabValue = 'docs';
      }
      else if (
        history.location.pathname.search(/\/assist$/) > -1
        ||
        history.location.pathname.search(/\/trips$/) > -1
      ) {
        contextTabValue = 'assist';
      }
      else if (history.location.pathname.search(/\/membrane$/) > -1) {
        contextTabValue = 'membrane';
      }
      else if (history.location.pathname.search(/\/orderparts$/) > -1) {
        contextTabValue = 'order';
      }
      else if (history.location.pathname.search(/order_history/) > -1) {
        contextTabValue = 'orderHist';
      }
      else if (
        history.location.pathname.search(/\/tenant\/[A-Za-z0-9]+\/?$/) > -1
        ||
        history.location.pathname.search(/\/tenant\/[A-Za-z0-9]+\/system\/[A-Za-z0-9]*\/?$/) > -1
        ||
        history.location.pathname.search(/\/tenant\/[A-Za-z0-9]+\/systems\/?$/) > -1
      ) {
        contextTabValue = 'overview';
      }

      // tab values for admin and premium customers
    } else {
      if (
        history.location.pathname.search(/\/tenant\/[A-Za-z0-9]+\/?$/) > -1
        ||
        history.location.pathname.search(/\/tenant\/[A-Za-z0-9]+\/system\/[A-Za-z0-9]*\/?$/) > -1
        ||
        history.location.pathname.search(/\/tenant\/[A-Za-z0-9]+\/systems\/?$/) > -1
      ) {
        contextTabValue = 'overview';
      } else if ( history.location.pathname.search(/\/trending\/?/) > -1) {
        contextTabValue = 'trending';
      } else if (history.location.pathname.search(/\/documents\/?/) > -1) {
        contextTabValue = 'docs';
      } else if (history.location.pathname.search(/\/hmi\/?/) > -1) {
        contextTabValue = 'hmi';
      } else if (history.location.pathname.search(/\/influent_/) > -1) {
        contextTabValue = 'influent';
      } else if (
        history.location.pathname.search(/\/assist/) > -1
        ||
        history.location.pathname.search(/\/trips$/) > -1
      ) {
        contextTabValue = 'assist';
      } else if (
        history.location.pathname.search(/\/membrane/) > -1
        ||
        history.location.pathname.search(/\/orderparts/) > -1
      ) {
        contextTabValue = 'membrane_parts';
      } else if (history.location.pathname.search(/\/order_history/) > -1) {
        contextTabValue = 'orderHist';
      }
    }

    return contextTabValue;
  }

  getSelectorOffset = () => {
    return document.getElementById('appNavBar') ? document.getElementById('appNavBar').offsetHeight : 0;
  }

  getContextOffset = () => {
    const { getSelectorOffset } = this;

    const selectHeight = document.getElementById('selectorNavBar') ? document.getElementById('selectorNavBar').offsetHeight : 0;

    return getSelectorOffset() + selectHeight;
  }

  getContainerOffset = () => {
    const { getContextOffset } = this;

    const contextHeight = document.getElementById('contextNavBar') ? document.getElementById('contextNavBar').offsetHeight : 0;

    return getContextOffset() + contextHeight;
  }

  handleResize = () => {
    const { getSelectorOffset, getContextOffset, getContainerOffset} = this;
    const { offsetHeight: containerOffset, updateOffsetHeight} = this.props;
    const { selectorOffset, contextOffset } = this.state;

    const newLocalState = {};

    // update selected offset if needed
    const currentSelectorOffset = getSelectorOffset();
    if (currentSelectorOffset !== selectorOffset) {
      newLocalState.selectorOffset = currentSelectorOffset;
    }

    // update context offset if needed
    const currentContextOffset = getContextOffset();
    if (currentContextOffset !== contextOffset) {
      newLocalState.contextOffset = currentContextOffset;
    }

    // set local state if updates present
    if (Object.keys(newLocalState).length > 0) {
      this.setState(newLocalState);
    }

    // update container offset height if needed
    const currentContainerOffset = getContainerOffset();
    if (currentContainerOffset !== containerOffset) {
      updateOffsetHeight(currentContainerOffset);
    }
  }

  AdminToggleLink = (localProps) => {
    const { getContextTabValue } = this;
    const { awsUserRole, classes, history } = this.props;

    const contextTabValue = getContextTabValue();

    if (awsUserRole !== 'admin') {
      return '';
    }

    if (contextTabValue === 'admin') {
      return (
        <Link
          href="#placeholder"
          className={[classes.appBarLink, classes.adminToggleLink].join(' ')}
          color="inherit"
          onClick={event => {
            event.preventDefault();
            history.push('/');
          }}
        >
          Standard View
        </Link>
      );
    }

    return (
      <Link
        href="#placeholder"
        className={[classes.appBarLink, classes.adminToggleLink].join(' ')}
        color="inherit"
        onClick={event => {
          event.preventDefault();
          history.push('/admin');
        }}
      >
        Customer Administration
      </Link>
    );
  }

  AlertComponent = (localProps) => {
    const { alerts, alertsOpen } = this.state;
    const { history, systems } = this.props;

    let alertIcon = null;
    if (alerts.length === 0) {
      alertIcon = <CheckIcon style={{color: 'white'}} fontSize="large"/>;
    } else if (alerts[0].level === 0) {
      alertIcon = <WarnIcon style={{color: 'orange'}} fontSize="large" />;
    } else {
      alertIcon = <ErrorIcon style={{color: 'red'}} fontSize="large" />;
    }

    const alertMenuItems = alerts.length > 0
      ? alerts.map((currentAlert, i) => {
        const systemName = systems[currentAlert.tag.system_id] ? systems[currentAlert.tag.system_id].name : '';
        const alertStyle = {};
        switch (currentAlert.level) {
          case (0):
            alertStyle.backgroundColor = warnColor;
            break;

          case (1):
            alertStyle.backgroundColor = severeAlertColor;
            break;

          case (2):
            alertStyle.backgroundColor = severeAlertColor;
            break;
          default:
            break;
        }
        return (
          <MenuItem
            key={i}
            alignItems="center"
            onClick={() => {
              history.push('/tenant/' + currentAlert.tag.tenant_id + '/system/' + currentAlert.tag.system_id);
              this.setState({alertsOpen: false});
            }}
            style={alertStyle}
          >
            {currentAlert.tag.name + ' ' + currentAlert.label} on {systemName}
          </MenuItem>
        );
      })
      : <MenuItem
          alignItems="center"
          onClick={() => this.setState({alertsOpen: false})}
        >
        <span style={{fontStyle: 'italic'}}>No Alerts</span>
      </MenuItem>;

      return (
        <>
          <IconButton
            id="alert-button"
            onClick={() => {
              this.setState({alertsOpen: !alertsOpen});
            }}
          >
            {alertIcon}
          </IconButton>
          <Menu
            open={alertsOpen}
            onClose={() => this.setState({alertsOpen: false})}
            anchorEl={document.getElementById('bell-button')}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            getContentAnchorEl={document.getElementById('alert-button')}
          >
            {alertMenuItems}
          </Menu>
        </>
      )
  }

  AppNavBar = (localProps) => {
    const { AdminToggleLink } = this;
    const { availTenants, classes, currentTenantId, history } = this.props;
    return (
      <AppBar id="appNavBar" position="fixed" className={classes.appBar}>
        <Grid container justify="space-between" alignItems="center" >
          <img height="35px" src={bwLogo} alt="SepTrac Logo"/>
          <div><Typography variant="h4" color="inherit">SepTrac</Typography></div>
          <div sytle={{display: 'flex'}}>
            <AdminToggleLink />
            <Link
              href="#placeholder"
              className={classes.appBarLink}
              color="inherit"
              onClick={event => {
                event.preventDefault();
                let reqTenantId = '';
                if (currentTenantId) {
                  reqTenantId = currentTenantId;
                } else if (Object.values(availTenants).length > 0) {
                  reqTenantId = Object.values(availTenants)[0].tenant_id;
                }
                if (reqTenantId) {
                  history.push(`/tenant/${reqTenantId}/problem`);
                }
              }}
            >
              Report A Problem
            </Link>
            <Link
              href="#placeholder"
              className={classes.appBarLink}
              color="inherit"
              onClick={(event) => {
                event.preventDefault();
                history.push('/contact');
              }}
            >
              Contact Us
            </Link>
            <Link
              href="#placeholder"
              className={classes.appBarLink}
              color="inherit"
              onClick={(event) => {
                event.preventDefault();
                Auth.signOut();
              }}
            >
              Log Out
            </Link>
          </div>
        </Grid>
      </AppBar>
    );
  }

  SelectorNavBar = (localProps) => {
    const { getContextTabValue } = this;
    const { availTenants, classes, currentTenantId, systems, systemId, history } = this.props;
    const { selectorOffset } = this.state;

    const contextTabValue = getContextTabValue();

    if (contextTabValue === 'admin') {
      return '';
    }

    const tenantList = Object.values(availTenants);
    const systemList = Object.values(systems)

    if ((tenantList.length <= 1) && (systemList.length <= 1)) {
      return null;
    }

    const selectorChildren = [];

    if (tenantList.length > 1) {
      const customerOptions = tenantList
      .sort(BY_NAME)
      .map(currentTenant => {
        return (
          <MenuItem
            key={`customer_select_${currentTenant.tenant_id}`}
            value={currentTenant.tenant_id}
            onClick={() => {
              history.push(`/tenant/${currentTenant.tenant_id}/systems`)
            }}
          >
            {currentTenant.name}
          </MenuItem>
        );
      });

      selectorChildren.push(
        <FormControl
          key="customer_selector"
          variant="outlined"
          margin="dense"
        >
          <InputLabel htmlFor="customer_selector_input">
            Customer
          </InputLabel>
          <Select
            value={currentTenantId ? currentTenantId : ''}
            label="Customer"
            inputProps={{id: 'customer_selector_input'}}
          >
            {customerOptions}
          </Select>
        </FormControl>
      );
    }

    // Make a list of Systems to navigate to
    const systemsOptions = Object.values(systems)
    .sort(BY_SORT_VALUE)
    .map(currentSystem => {
      return (
        <MenuItem
          key={`system_select_${currentSystem.system_id}`}
          value={currentSystem.system_id}
          onClick={() => {
            history.push(`/tenant/${currentSystem.tenant_id}/system/${currentSystem.system_id}`);
          }}
        >
          {currentSystem.name}
        </MenuItem>
      );
    });
    selectorChildren.push(
      <FormControl
        key="system_selector"
        variant="outlined"
        margin="dense"
      >
        <InputLabel htmlFor="system_selector_input">
         System
        </InputLabel>
        <Select
          value={systemId !== 'listView' ? systemId : ''}
          label="System"
          inputProps={{id: 'system_selector_input'}}
        >
          {systemsOptions}
        </Select>
      </FormControl>
    );

    return (
      <AppBar id="selectorNavBar" position="fixed" classes={{root: classes.selectorNavBar}} style={{top: selectorOffset}}>
        <Grid container>
          {selectorChildren}
        </Grid>
      </AppBar>
    );
  }

  ContextNavBar = (localProps) => {
    const { getContextTabValue, LiveDataArrow, HmiArrow, InfluentArrow, MembraneArrow } = this
    const { availTenants, awsUserRole, classes, currentTenantId, history, systemId } = this.props;
    const { contextOffset, liveDataMenuOpen, hmiMenuOpen, influentMenuOpen, membraneMenuOpen } = this.state;

    // figure out where the the app is
    let contextTabValue = getContextTabValue();

    // dont show if in "admin" mode
    if (contextTabValue === 'admin') {
      return '';
    }
    const pickedTenant = (currentTenantId);
    const pickedSystem = systemId === 'listView' ? false : systemId;

    // dont show if no system selected
    if (!pickedSystem) {
      return '';
    }

    let tabsContent = ''
    // tabs for regular customer
    if (awsUserRole === 'customer') {
      tabsContent = (
        <Tabs
          value={contextTabValue}
          variant="scrollable"
          scrollButtons="on"
          classes={{ flexContainer: classes.navFlex, indicator: classes.navIndicator }}>
          <Tab
            label="OVERVIEW"
            value="overview"
            onClick={() => {
              if (currentTenantId) {
                if (pickedSystem) {
                  history.push(`/tenant/${currentTenantId}/system/${pickedSystem}`)
                } else {
                  history.push(`/tenant/${currentTenantId}`);
                }
              } else {
                history.push(`/tenant/${Object.keys(availTenants)[0]}`)
              }
            }}
          />
          <Tab
            label="DOCUMENTS"
            value="docs"
            onClick={() => {
              history.push(`/tenant/${currentTenantId}/system/${systemId}/documents`);
            }}
            disabled={!pickedSystem}
          />
          <Tab
            label="KSS ASSIST"
            value="assist"
            onClick={() => {
              history.push(`/tenant/${currentTenantId}/assist`);
            }}
            disabled={!pickedTenant}
          />
          <Tab
            label="INSTALLED MEMBRANES"
            value="membrane"
            onClick={() => {
              history.push(`/tenant/${currentTenantId}/system/${systemId}/membrane`);
            }}
            disabled={!pickedSystem}
          />
          <Tab
            label="ORDER PARTS"
            value="order"
            onClick={() => {
              history.push(`/tenant/${currentTenantId}/system/${systemId}/orderparts`);
            }}
            disabled={!pickedSystem}
          />
          <Tab
            label="ORDER HISTORY"
            value="orderHist"
            onClick={() => {
              history.push(`/tenant/${currentTenantId}/order_history`);
            }}
            disabled={!pickedTenant}
          />
        </Tabs>
      );

    // Tabs for admin and premium customer
    } else {
      tabsContent = (
        <Tabs
          value={contextTabValue}
          variant="scrollable"
          scrollButtons="on"
          classes={{flexContainer: classes.navFlex, indicator: classes.navIndicator, scrollButtons: classes.contextTabScrollButton}}
        >
          <Tab
            label="OVERVIEW"
            value="overview"
            onClick={() => {
              if (currentTenantId) {
                if (pickedSystem) {
                  history.push(`/tenant/${currentTenantId}/system/${pickedSystem}`)
                } else {
                  history.push(`/tenant/${currentTenantId}`);
                }
              } else {
                history.push(`/tenant/${Object.keys(availTenants)[0]}`)
              }
            }}
          />
          <Tab
            id="livedata_tab"
            label={<>LIVE DATA <LiveDataArrow /></>}
            value="trending"
            classes={{wrapper: classes.rowTab}}
            onClick={() => {
              this.setState({liveDataMenuOpen: !liveDataMenuOpen});
            }}
          />
          <Tab
            label="DOCUMENTS"
            value="docs"
            onClick={() => {
              history.push(`/tenant/${currentTenantId}/system/${pickedSystem}/documents`);
            }}
          />
          <Tab
            id="hmi_tab"
            label={<>HMI VIEW <HmiArrow /></>}
            value="hmi"
            classes={{wrapper: classes.rowTab}}
            onClick={() => {
              this.setState({hmiMenuOpen: !hmiMenuOpen})
            }}
          />
          <Tab
            id="influent_tab"
            label={<>INFLUENT <InfluentArrow /></>}
            value="influent"
            classes={{wrapper: classes.rowTab}}
            onClick={() => {
              this.setState({influentMenuOpen: !influentMenuOpen})
            }}
          />
          <Tab
            label="KSS ASSIST"
            value="assist"
            onClick={() => {
              history.push(`/tenant/${currentTenantId}/assist`);
            }}
          />
          <Tab
            id="membrane_tab"
            label={<>MEMBRANES AND PARTS <MembraneArrow /></>}
            value="membrane_parts"
            classes={{wrapper: classes.rowTab}}
            onClick={() => {
              this.setState({membraneMenuOpen: !membraneMenuOpen})
            }}
          />
          <Tab
            label="ORDERS"
            value="orderHist"
            onClick={() => {
              history.push(`/tenant/${currentTenantId}/order_history`);
            }}
          />

        </Tabs>
      );
    }

    return (
      <AppBar id="contextNavBar" position="fixed" classes={{root: classes.contextNavBar}} style={{top: contextOffset}}>
        {tabsContent}
      </AppBar>
    )
  }

  LiveDataArrow = (localProps) => {
    const { liveDataMenuOpen } = this.state;
    const { classes } = this.props;

    if (liveDataMenuOpen) {
      return <DoubleArrow classes={{root: classes.rotatedArrow}} />
    } else {
      return <DoubleArrow classes={{root: classes.regArrow}} />
    }
  }

  HmiArrow = (localProps) => {
    const { hmiMenuOpen } = this.state;
    const { classes } = this.props;

    if (hmiMenuOpen) {
      return <DoubleArrow classes={{root: classes.rotatedArrow}} />
    } else {
      return <DoubleArrow classes={{root: classes.regArrow}} />
    }
  }

  InfluentArrow = (localProps) => {
    const { influentMenuOpen } = this.state;
    const { classes } = this.props;

    if (influentMenuOpen) {
      return <DoubleArrow classes={{root: classes.rotatedArrow}} />
    } else {
      return <DoubleArrow classes={{root: classes.regArrow}} />
    }

  }

  MembraneArrow = (localProps) => {
    const { membraneMenuOpen } = this.state;
    const { classes } = this.props;

    if (membraneMenuOpen) {
      return <DoubleArrow classes={{root: classes.rotatedArrow}} />
    } else {
      return <DoubleArrow classes={{root: classes.regArrow}} />
    }
  }

  ContextMenus = (localProps) => {
    const { liveDataMenuOpen, hmiMenuOpen, influentMenuOpen, membraneMenuOpen } = this.state;
    const { history, systems, systemId } = this.props;

    const currentSystem = systems[systemId];

    let livedataMenuItems = currentSystem
      ? currentSystem.subsystems
      ?.sort(BY_SORT_VALUE)
      .map(currentSubsystem => {
        return (
          <MenuItem
            key={`livedata_option_${currentSubsystem.subsystem_id}`}
            onClick={() => {
              this.setState({liveDataMenuOpen: false});
              history.push(`/tenant/${currentSubsystem.tenant_id}/system/${currentSubsystem.system_id}/subsys/${currentSubsystem.subsystem_id}/trending`)
            }}
          >

            {currentSubsystem.name}
          </MenuItem>
        );
      })
      : [];

    const currentSystemTags = currentSystem?.tags ? currentSystem.tags.filter(currentTag => currentTag.is_trending) : [];

    if (!livedataMenuItems || livedataMenuItems.length === 0) {
      livedataMenuItems = (
        <MenuItem
          onClick={() => {
            this.setState({liveDataMenuOpen: false});
          }}
        >
          <span style={{fontStyle: 'italic'}}>No Subsystems</span>
        </MenuItem>
      );
    }

    if (currentSystemTags.length === 0) {
      livedataMenuItems = (
        <MenuItem
          onClick={() => {
            this.setState({liveDataMenuOpen: false});
          }}
        >
          <span style={{fontStyle: 'italic'}}>No Data Tags</span>
        </MenuItem>
      );
    }

    let hmiMenuItems = currentSystem
      ? currentSystem.hmis?.map((currentHmi, i) => {
        return (
          <MenuItem
            key={`hmi_menuitem_${i}`}
            onClick={() => {
              this.setState({hmiMenuOpen: false});
              history.push(`/tenant/${currentSystem.tenant_id}/system/${systemId}/hmi/${encodeURIComponent(currentHmi.name)}`);
            }}
          >
            {currentHmi.name}
          </MenuItem>
        );
      })
      : [];

    if (!hmiMenuItems || hmiMenuItems.length === 0) {
      hmiMenuItems = (
        <MenuItem
          onClick={() => this.setState({hmiMenuOpen: false})}
        >
          <span style={{fontStyle: 'italic'}}>No HMI's</span>
        </MenuItem>
      );
    }

    const currentInputs = currentSystem?.inputs ? currentSystem.inputs : [];
    const influentMenuItems = currentInputs.length > 0
      ? (
        [
          <MenuItem
            key="influent_chart_item"
            onClick={() => {
              this.setState({influentMenuOpen: false});
              history.push(`/tenant/${currentSystem.tenant_id}/system/${currentSystem.system_id}/influent_charts`);
            }}
          >
            Charts
          </MenuItem>,
          <MenuItem
            key="influent_log_item"
            onClick={() => {
              this.setState({influentMenuOpen: false});
              history.push(`/tenant/${currentSystem.tenant_id}/system/${currentSystem.system_id}/influent_logs`);
            }}
          >
            Logs
          </MenuItem>,
          <MenuItem
            key="influent_entry_item"
            onClick={() => {
              this.setState({influentMenuOpen: false});
              history.push(`/tenant/${currentSystem.tenant_id}/system/${currentSystem.system_id}/influent_enter`);
            }}
          >
            Enter Data
          </MenuItem>
        ]
      )
      : (
        <MenuItem
          onClick={() => {
            this.setState({influentMenuOpen: false});
          }}
        >
          <span style={{fontStyle: 'italic'}}>No Influent Tags</span>
        </MenuItem>
      );

    return (
      <>
        <Menu
          open={liveDataMenuOpen}
          onClose={() => this.setState({liveDataMenuOpen: false})}
          anchorEl={liveDataMenuOpen ? document.getElementById('livedata_tab') : null}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          {livedataMenuItems}
        </Menu>
        <Menu
          open={hmiMenuOpen}
          onClose={() => this.setState({hmiMenuOpen: false})}
          anchorEl={hmiMenuOpen ? document.getElementById('hmi_tab') : null}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          {hmiMenuItems}
        </Menu>
        <Menu
          open={influentMenuOpen}
          onClose={() => this.setState({influentMenuOpen: false})}
          anchorEl={influentMenuOpen ? document.getElementById('influent_tab') : null}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          {influentMenuItems}
        </Menu>
        <Menu
          open={membraneMenuOpen}
          onClose={() => this.setState({membraneMenuOpen: false})}
          anchorEl={document.getElementById('membrane_tab')}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          <MenuItem
            onClick={() => {
              this.setState({membraneMenuOpen: false});
              history.push(`/tenant/${currentSystem.tenant_id}/system/${currentSystem.system_id}/membrane`);
            }}
          >
            Installed Membranes
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.setState({membraneMenuOpen: false});
              history.push(`/tenant/${currentSystem.tenant_id}/system/${currentSystem.system_id}/orderparts`);
            }}
          >
            Order Parts
          </MenuItem>
        </Menu>
      </>
    )
  }

  render() {
    const { AppNavBar, ContextNavBar, SelectorNavBar, ContextMenus } = this;
    return (
      <>
        <AppNavBar />
        <SelectorNavBar />
        <ContextNavBar />
        <ContextMenus />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  availTenants: state.availTenants,
  currentTenantId: state.currentTenantId,
  offsetHeight: state.offsetHeight,
  subsystemId: state.currentSubsystemId,
  systemId: state.currentSystemId,
  systems: state.systems,
  awsUserRole: state.awsUserRole,
});

const mapDispatchToProps = {
  fetchStatusBySystem,
  updateCurrentSystemId,
  updateOffsetHeight,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(
   withStyles(style)(TopNavigation)
 )
);

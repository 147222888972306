/**
 * Material UI Theme for KSS
 */
import {
  fontFamily,
  darkBlue, midBlue,
  warnColor, errorColor,
} from './commonStyles';

export const defaultTheme = {
  palette: {
    primary: {
      main: darkBlue,
      light: midBlue,
    },
    error: {
      main: errorColor,
    },
    warning: {
      main: warnColor,
    },
  },
  typography: {
    fontFamily
  },
  overrides: {
    MuiTableCell: {
      head: {
        color: midBlue,
      }
    }
  }
};

import React from 'react';

import { connect } from 'react-redux';

import TopNavigation from './TopNavigation';
import Container from '@material-ui/core/Container';

class AppWrapper extends React.Component {
  render() {
    const { children, offsetHeight } = this.props;

    /* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */
    return (

      <>
        <TopNavigation />
        <div style={{height: `${offsetHeight}px`}}></div>
        <Container
          id="container"
          style={{
            flexGrow: '1',
            alignItems: 'stretch',
            paddingTop: '24px'
          }}
        >
          <a id="top"></a>
          {children}
          <div style={{height: '51px'}}></div>
        </Container>
      </>

    );
  }
}

const mapStateToProps = state => ({
  offsetHeight: state.offsetHeight,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper)

/**
 * Show errors in popup dialogs
 */
import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import ClipboardIcon from '@material-ui/icons/Assignment';

import { dismissError } from '../../reduxElements/actions';

const styles = theme => ({
  dialog: {
    '& .MuiDialogContentText-root': {
      whiteSpace: 'pre-wrap',
    },
    '& .MuiDialogTitle-root': {
      color:           theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.dark,
    },
  },
  dismiss: {
    color:           theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.dark,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
  errorTextStyle: {
    color: theme.palette.text.primary,
  }
});

/**
 * Copy the error text to the user's clipboard.
 */
function copyToClipboard(error) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(error.text);
  }
}

function Errors(props) {
  const { errors, dismissError, classes } = props;

  if (!errors || (errors.length === 0)) {
    return null;
  }

  // Copying to the clipboard may be disabled
  const can_copy = navigator.clipboard;

  // Take the first error
  const error = errors[0];

  return (
    <Dialog open onClose={dismissError}
      maxWidth="sm" fullWidth disableBackdropClick
      classes={{root: classes.dialog}}
    >
      <DialogTitle>
        {error.title ? error.title : 'Error'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText classes={{root: classes.errorTextStyle}}>
          {error.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <IconButton
          onClick={() => copyToClipboard(error)}
          disabled={!can_copy}
          title="Copy to clipboard"
        >
          <ClipboardIcon />
        </IconButton>
        <Button onClick={dismissError} className={classes.dismiss}>
          Dismiss Error
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  errors: state.errors
});

const mapDispatchToProps = {
  dismissError
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Errors));

export const fontFamily = '"Roboto", sans-serif';

// KSS Palette
export const midBlue = '#0093d0'
export const darkBlue = '#005a8a'

export const errorColor = '#B50000'; // from heui-error
export const warnColor = '#FFA500'; // '#F7FA61';
export const alertColor = '#FAB061';
export const severeAlertColor = '#FA6161';

export const adminContainer = (theme) => ({
  '& > *': {
    marginTop: theme.spacing(3),
  },
});

export const adminActionButtonsItem = (theme) => ({
  '& > button': {
    marginLeft: theme.spacing(2),
  }
});

export const headerStyles = {
    backgroundColor: darkBlue,
    color: 'white',
};

export const paperContainer = {
    backgroundColor: 'white',
    backgroundSize: 'cover',
    position: 'relative',
    width: '100%',
    height: '100vh'
};

export const topHeader = {
  color: midBlue,
  textAlign: 'center',
};

export const subsystemHeadersAndRows = {
  ...headerStyles,
};

export const subsystemHeaders = {
  borderTopLeftRadius: "7px",
  borderTopRightRadius: "7px"
};


export const activeButtonColors = {
  ...subsystemHeadersAndRows,
};

export const disabledButtonColors = {
  ...activeButtonColors,
  opacity: 0.5
};

export const linkButton = {
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  fontSize: 'inherit',
  textDecoration: 'underline',
  display: 'inline',
  margin: '0',
  padding: '0',
};

export const oddBGColor = {
  backgroundColor: '#E6EDE8',
};

export const cardContainer = {
  marginTop: '3em',
};

export const darkDivider = (theme) => ({
  backgroundColor: theme.palette.text.hint,
});

import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';
import { Hub, I18n } from '@aws-amplify/core';

import {
  setAvailTenants,
  setAwsUser
} from './reduxElements/actions';

/**
 * Add the Authentication header to all Amplify API requests
 */
const add_auth = async () => {
  const session = await Auth.currentSession()
  const token = session.getIdToken().getJwtToken();
  return {
    Authorization: `Bearer ${token}`
  };
};

const configs = {
  Auth: {
    region: 'us-east-2',
    userPoolId: 'us-east-2_DVyl6WFZ5',
    userPoolWebClientId: '6m7i5kih60eerv9uhk9gekfp10',
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: "data",
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        custom_header: add_auth,
      },
      {
        name: "barcode",
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        custom_header: add_auth,
      },
    ],
  },
};

// Text labels for the sign up form
const AMPLIFY_LABELS = {
  en: {
    'Sign in to your account': 'Welcome to SepTrac'
  }
};

/**
 * Handle a Cognito user signing in
 * @param {*} reduxStore 
 */
async function handleSignIn(reduxStore) {
  const state = reduxStore.getState();
  if (state.awsUser) {
    console.log('handleSignIn: User is already signed in');
    return;
  }

  const user = await Auth.currentAuthenticatedUser();
  if (!user) {
    console.log('handleSignIn: User is null');
    return;
  }

  // Store the new user
  reduxStore.dispatch(setAwsUser(user))
}

/**
 * Handle a Cognito user explicitly signing out
 * 
 * @param {*} reduxStore 
 */
async function handleSignOut(reduxStore) {
  // Purge the global state
  reduxStore.dispatch(setAvailTenants({}));

  // Purge the session
  reduxStore.dispatch(setAwsUser(null));

  // Go to the home page after sign-out to clear the URL
  window.location = '/';
}

export default function(reduxStore) {
  // Configure Amplify 
  Auth.configure(configs.Auth);
  API.configure(configs.API);

  // Customize the text labels
  I18n.putVocabularies(AMPLIFY_LABELS);

  // Listen for AWS authentication events (eg: sign-in, sign-out)
  Hub.listen('auth', async (data) => {
    const { payload } = data;
    switch (payload.event) {
      case 'signIn':
        handleSignIn(reduxStore);
        break;

      case 'signOut':
        handleSignOut(reduxStore);
        break;

      default:
        break;
    }
  });

  // Listen for events from the AmplifyAuthenticator
  Hub.listen('UI Auth', async (data) => {
    const { payload } = data
    if (payload?.event === 'AuthStateChange') {
      switch (payload.message) {
        case 'signedin':
          // User is already logged in
          handleSignIn(reduxStore);
          break;
        default:
          break;
      }
    }
  });
}

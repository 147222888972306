import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
// Load the Roboto Typeface
import 'typeface-roboto';
import App from './App';
import awsConfig from './awsconfig';
import reduxReducer from './reduxElements/index';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './styles/index.css';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reduxStore = createStore(
  reduxReducer as any,
  composeEnhancers(applyMiddleware(thunk)),
);

awsConfig(reduxStore);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={reduxStore}>
      <App />
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

import React from 'react';

import { connect } from 'react-redux';
import { initTenants } from './reduxElements/thunkActions';
import { addError } from './reduxElements/actions';

import { Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';

import LoadPage from './components/LoadPage';

import { defaultTheme } from './styles/themes';
import './styles/App.css';

import AppWrapper from './components/common/AppWrapper';
import SignIn from './components/SignIn';
import Errors from './components/common/Errors';
const Contact = React.lazy(() => import('./components/Contact'));
const TenantRouter = React.lazy(() => import('./components/TenantRouter/'));
const AdminRouter = React.lazy(() => import('./components/admin-ui/AdminRouter'));
const BusyIndicator = React.lazy(() => import('./components/common/BusyIndicator'));

// Build the default theme
const theme = createMuiTheme(defaultTheme);

class App extends React.Component {
  state = {
    // Was there a rendering error?
    hasError: false,
  }

  // Note an error from a child component
  static getDerivedStateFromError(error) {
    return {
      hasError: true
    };
  }

  componentDidMount() {
    this.props.initTenants();
  }

  componentDidUpdate(prevProps) {
    const { awsUser, initTenants } = this.props;

    if (awsUser !== prevProps.awsUser) {
      initTenants();
    }
  }

  // Setup an error boundary to handle errors from child components
  componentDidCatch(error, info) {
    const { addError } = this.props;

    console.log('componentDidCatch', error);

    addError(error);
  }

  TenantRedirect = () => (
    <Redirect
      to={`/tenant/${Object.keys(this.props.availTenants)[0]}/systems`}
    />
  )

  render() {
    const { TenantRedirect } = this;
    const { awsUser, availTenants } = this.props;

    /*
    const { hasError } = this.state;
    if (hasError)  {
      // Show child component errors
      // These will likely prevent rendering, so we don't try try to put the
      // usual children on the page.
      return (
        <React.Fragment>
          <Errors />
          <Typography variant="display2">
            Please reload the page.
          </Typography>
        </React.Fragment>
      );
    }
    */

    if (!awsUser) {
      return (
        <MuiThemeProvider theme={theme}>
          <SignIn />
        </MuiThemeProvider>
      );
    }

    if (Object.keys(availTenants).length === 0) return <LoadPage />;

    return (
      <MuiThemeProvider theme={theme}>
        <Errors />
        <Router>
          <AppWrapper>
            <React.Suspense fallback={<LoadPage />}>
              <BusyIndicator />
              <Route exact path="/tenant" component={TenantRedirect} />
              <Route path="/tenant/:tenantid" component={TenantRouter} />
              <Route path="/contact" component={Contact} />
              <Route exact strict path={["/", "/index", "/index.html", "/systems"]} component={TenantRedirect} />
              <Route path="/admin" component={AdminRouter} />
            </React.Suspense>
          </AppWrapper>
        </Router>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  awsUser: state.awsUser,
  availTenants: state.availTenants,
  currentTenantId: state.currentTenantId,
  uiBusy: state.uiBusy
});

const matchDispatchToProps = {
  initTenants,
  addError,
};

export default connect(mapStateToProps, matchDispatchToProps)(
  App
)

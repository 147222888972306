import API from '@aws-amplify/api';

import { addError } from '../reduxElements/actions';

/**
 * Core API request, now with built-in error handling!
 *
 * HTTP and API errors are dispatched through redux.
 *
 * The response is null in the case of an error.
 *
 * Note: You'll need to call API.* directly if you want handle the errors yourself.
 *
 * @param dispatch Redux store dispatch function
 * @param method HTTP method (GET, DELETE, PATCH, POST, PUT)
 * @param ...args all the remaining args to pass to Amplify.API
 * @returns async API response, or null if there was an error
 */
export const api_request = async (dispatch, method, ...args) => {
  try {
    switch (method) {
      case 'GET':    return await API.get(...args);
      case 'DELETE': return await API.del(...args);
      case 'PATCH':  return await API.patch(...args);
      case 'POST':   return await API.post(...args);
      case 'PUT':    return await API.put(...args);
      default:
        throw new Error(`Unknown API method '${method}`);
    }
  }
  catch (err) {
    // Log the error
    console.log(`API ERROR:`, method, args, err.response);

    // Present the error to the user
    dispatch(addError(err));

    // Nothing to return
    return null;
  }
}

export const fetchAvailTenants = () => async (dispatch) => {
  return api_request(dispatch, 'GET', 'data', `/tenants`);
}

export const postTenant = (newTenant) => async (dispatch) => {
  return api_request(dispatch, 'POST', 'data', `/tenants`, {
    body: newTenant
  });
}

export const patchTenant = (newTenant) => async (dispatch) => {
  return api_request(dispatch, 'PATCH', 'data', `/tenants/${newTenant.tenant_id}`, {
    body: newTenant
  });
}

export const deleteTenant = (tenantId) => async (dispatch) => {
  return api_request(dispatch, 'DELETE', 'data', `/tenants/${tenantId}`);
}

export const fetchSystemsByTenantId = (tenantId) => (dispatch) => {
  return api_request(dispatch, 'GET', 'data', `/tenants/${tenantId}/systems`);
}

export const fetchSystemById = (tenantId, systemId) => (dispatch) => {
  return api_request(dispatch, 'GET', 'data', `/tenants/${tenantId}/systems/${systemId}`);
}

export const postSystem = (newSystem) => async (dispatch) => {
  return api_request(dispatch, 'POST', 'data',
    `/tenants/${newSystem.tenant_id}/systems`,
    {
      body: newSystem
    }
  );
}

export const patchSystem = (newSystem) => async (dispatch) => {
  const returnedSystem = await api_request(dispatch, 'PATCH', 'data',
    `/tenants/${newSystem.tenant_id}/systems/${newSystem.system_id}`,
    {
      body: newSystem
    }
  );

  if (!returnedSystem) {
    return null;
  }

  return {
    ...newSystem,
    ...returnedSystem,
  }
}

export const deleteSystem = (tenantId, systemId) => async (dispatch) => {
  return api_request(dispatch, 'DELETE', 'data',
    `/tenants/${tenantId}/systems/${systemId}`
  );
}

export const fetchSystemPerformance = (tenantId, systemId) => async (dispatch) => {
  return api_request(dispatch, 'GET', 'data',
    `/tenants/${tenantId}/systems/${systemId}/performance`
  );
}

export const fetchSystemSnapshot = (tenantId, systemId) => async (dispatch) => {
  return api_request(dispatch, 'GET', 'data',
    `/tenants/${tenantId}/systems/${systemId}/snapshot`
  );
}

export const fetchStatusBySystem = (tenantId, systemId) => async (dispatch) => {
  return api_request(dispatch, 'GET', 'data',
    `/tenants/${tenantId}/systems/${systemId}/status`
  );
}

export const fetchSubsystemById = (tenantId, subSystemId) => async (dispatch) => {
  return api_request(dispatch, 'GET', 'data',
    `/tenants/${tenantId}/subsystems/${subSystemId}`
  );
}

export const postSubsystem = (newSubsystem) => async (dispatch) => {
  return api_request(dispatch, 'POST', 'data',
    `/tenants/${newSubsystem.tenant_id}/subsystems/`,
    {
      body: newSubsystem,
    }
  );
}

export const patchSubsystem = (newSubsystem) => async (dispatch) => {
  return api_request(dispatch, 'PATCH', 'data',
    `/tenants/${newSubsystem.tenant_id}/subsystems/${newSubsystem.subsystem_id}`,
    {
      body: newSubsystem,
    }
  );
}

export const deleteSubsystem = (tenantId, subsystemId) => async (dispatch) => {
  return api_request(dispatch, 'DELETE', 'data',
    `/tenants/${tenantId}/subsystems/${subsystemId}`
  );
}

export const fetchInfluentInputTypes = (tenantId, systemId) => async (dispatch) => {
  return api_request(dispatch, 'GET', 'data',
    `/tenants/${tenantId}/systems/${systemId}/inputs`
  );
}

/**
 * Fetch recent Influent log entries
 *
 * @param tenantId Tenant ID
 * @param systemId System ID
 * @param args
 * - inputs: Array of input IDs to fetch
 * - start_utc: Beginning of time range
 * - end_utc: End of time range
 */
export const fetchInfluentLogs = (tenantId, systemId, args) => async (dispatch) => {
  return api_request(dispatch, 'POST', 'data',
    `/tenants/${tenantId}/systems/${systemId}/influent/log`,
    {
      body: args,
    }
  );
}

/**
 * Create a new Influent log entry
 *
 * @param tenantId Tenant ID
 * @param systemId System ID
 * @param timestamp UTC datetime string
 * @param inputs Array of input values, like:
 *   [
 *     {
 *       input_id : 'pH',
 *       value: '7.0'
 *     }, ...
 *   ]
 */
export const putInfluentEntry = (tenantId, systemId, timestamp, inputs) => async (dispatch) => {
  return api_request(dispatch, 'POST', 'data',
    `/tenants/${tenantId}/systems/${systemId}/influent/entry`,
    {
      body: {
        timestamp,
        inputs
      }
    }
  );
}

export const fetchPartsBySystem = (tenantId, systemId) => async (dispatch) => {
  return api_request(dispatch, 'GET', 'data',
    `/tenants/${tenantId}/systems/${systemId}/parts`
  );
}

export const putPartOrder = (tenantId, systemId, inputOrder) => async (dispatch) => {
  // const exampleInputOrder = [
  //   {partNumber: '012345', orderQty: 2},
  // ];
  return api_request(dispatch, 'POST', 'data',
    `/tenants/${tenantId}/parts/order`,
    {
      body: inputOrder
    }
  );
}

export const postPart = (tenantId, newPart) => async (dispatch) => {
  return api_request(dispatch, 'POST', 'data',
    `/tenants/${tenantId}/parts`,
    {
      body: newPart
    }
  );
}

export const patchPart = (newPart) => async (dispatch) => {
  return api_request(dispatch, 'PATCH', 'data',
    `/tenants/${newPart.tenant_id}/parts/${newPart.part_id}`,
    {
      body: newPart
    }
  );
}

export const deletePart = (tenantId, partId) => async (dispatch) => {
  return api_request(dispatch, 'DELETE', 'data',
    `/tenants/${tenantId}/parts/${partId}`
  );
}

export const fetchDocumentsBySystem = (tenantId, systemId) => async (dispatch) => {
  return api_request(dispatch, 'GET', 'data',
    `/tenants/${tenantId}/systems/${systemId}/docs`
  );
}

export const postDocument = (tenantId, newDocument) => async (dispatch) => {
  return api_request(dispatch, 'POST', 'data',
    `/tenants/${tenantId}/docs`,
    {
      body: newDocument,
    }
  );
}

export const patchDocument = (newDocument) => async (dispatch) => {
  return api_request(dispatch, 'PATCH', 'data',
    `/tenants/${newDocument.tenant_id}/docs/${newDocument.doc_id}`,
    {
      body: newDocument,
    }
  );
}

export const getDocUploadPath = (tenantId, path) => async (dispatch) => {
  return api_request(dispatch, 'GET', 'data',
    `/tenants/${tenantId}/docs/upload_url/${path}`
  );
  // return {
  //   path,
  //   put_url: 'put_url',
  //   headers: {},
  //   s3_url: 's3_url'
  // };
}

export const getTripUploadPath = (tenantId, path) => async (dispatch) => {
  return api_request(dispatch, 'GET', 'data',
    `/tenants/${tenantId}/trip_reports/upload_url/${path}`
  );
}

export const getDocumentUrl = (tenantId, docId) => async (dispatch) => {
  return api_request(dispatch, 'GET', 'data',
    `/tenants/${tenantId}/docs/${docId}/url`
  );
}

export const fetchModulesBySubsystem = (tenantId, subsystemId) => async (dispatch) => {
  return api_request(dispatch, 'GET', 'data',
    `/tenants/${tenantId}/subsystems/${subsystemId}/modules`
  );
}

export const postModule = (tenantId, newModule) => async (dispatch) => {
  return api_request(dispatch, 'POST', 'data',
    `/tenants/${tenantId}/modules`,
    {
      body: newModule,
    }
  );
}

export const patchModule = (newModule) => async (dispatch) => {
  return api_request(dispatch, 'PATCH', 'data',
    `/tenants/${newModule.tenant_id}/modules/${newModule.module_id}`,
    {
      body: newModule,
    }
  );
}

export const deleteModule = (tenantId, moduleId) => async (dispatch) => {
  return api_request(dispatch, 'DELETE', 'data',
    `/tenants/${tenantId}/modules/${moduleId}`
  );
}

export const fetchPositionsByModule = (tenantId, moduleId) => async (dispatch) => {
  return api_request(dispatch, 'GET', 'data',
    `/tenants/${tenantId}/modules/${moduleId}/positions`
  );
}

export const postPosition = (tenantId, newPosition) => async (dispatch) => {
  return api_request(dispatch, 'POST', 'data',
    `/tenants/${tenantId}/positions`,
    {
      body: newPosition,
    }
  );
}

export const patchPosition = (tenantId, positionId, updates) => async (dispatch) => {
  return api_request(dispatch, 'PATCH', 'data',
    `/tenants/${tenantId}/positions/${positionId}`,
    {
      body: updates,
    }
  );
}

export const deletePosition = (tenantId, positionId) => async (dispatch) => {
  return api_request(dispatch, 'DELETE', 'data',
    `/tenants/${tenantId}/positions/${positionId}`
  );
}

export const putProblemReport = (tenantId, problemReport) => async (dispatch) => {
  return api_request(dispatch, 'POST', 'data',
    `/tenants/${tenantId}/reportproblem`,
    {
      body: problemReport,
    }
  );
}

export const getUsers = () => async (dispatch) => {
  const data = await api_request(dispatch, 'GET', 'data', `/users`);

  if (!data) {
    return null;
  }

  const output = {}
  data.forEach(currentUser => {
    output[currentUser.username] = currentUser;
  });

  return output
}

export const postUser = (newUser) => async (dispatch) => {
  return api_request(dispatch, 'POST', 'data',
    `/users`,
    {
      body: newUser,
    }
  );
}

export const patchUser = (updatedUser) => async (dispatch) => {
  return api_request(dispatch, 'PATCH', 'data',
    `/users/${updatedUser.username}`,
    {
      body: updatedUser,
    }
  );
}

export const deleteUser = (deletedUserId) => async (dispatch) => {
  return api_request(dispatch, 'DELETE', 'data',
    `/users/${deletedUserId}`,
  );
}

export const getTripReports = (tenantId) => async (dispatch) => {
  return api_request(dispatch, 'GET', 'data',
    `/tenants/${tenantId}/trip_reports`
  );
}

export const postTripReport = (tenantId, tripReport) => async (dispatch) => {
  return api_request(dispatch, 'POST', 'data',
    `/tenants/${tenantId}/trip_reports/`,
    {
      body: tripReport,
    }
  );
}

export const patchTripReport = (tenantId, tripReportId, tripReport) => async (dispatch) => {
  return api_request(dispatch, 'PATCH', 'data',
    `/tenants/${tenantId}/trip_reports/${tripReportId}`,
    {
      body: tripReport,
    }
  );
}


export const deleteTripReport = (tenantId, tripReportId) => async (dispatch) => {
  return api_request(dispatch, 'DELETE', 'data',
    `/tenants/${tenantId}/trip_reports/${tripReportId}`,
  );
}

export const getTripReportUrl = (tenantId, tripReportId) => async (dispatch) => {
  return api_request(dispatch, 'GET', 'data',
    `/tenants/${tenantId}/trip_reports/${tripReportId}/url`
  );
}

/**
 * Fetch the trending data for a subsystem
 *
 * @param tenantId Tenant ID
 * @param systemId System ID
 * @param args Object of
 *   - tags: array of tag IDs
 *   - start_utc: beginning of time range
 *   - end_utc: end of time range
 */
export const fetchTrendingBySubsystem = (tenantId, subSystemId, args) => async(dispatch) => {
  return api_request(dispatch, 'POST', 'data',
    `/tenants/${tenantId}/subsystems/${subSystemId}/trending`, {
      body: args
    }
  );
}

/**
 * Fetch the order history for a tenant
 */
export const getOrderHistory = (tenantId) => async (dispatch) => {
  return api_request(dispatch, 'GET', 'data', `/tenants/${tenantId}/orders`);
}

/**
 * Cause an API error
 */
export const getApiError = (tenantId) => async (dispatch) => {
  return api_request(dispatch, 'GET', 'data', `/error`);
}

import {
  fetchAvailTenants, patchTenant,
  fetchSystemsByTenantId, fetchSystemById, postSystem, patchSystem, deleteSystem as remotingDeleteSystem,
  postSubsystem, patchSubsystem, deleteSubsystem as remotingDeleteSubsystem,
  postModule, patchModule, deleteModule as remotingDeleteModule,
  postPosition, patchPosition, deletePosition as remotingDeletePosition,
  getUsers as remotingGetUsers, postUser, patchUser, deleteUser as remotingDeleteUser,
  getDocUploadPath,
  postPart, patchPart, deletePart as remotingDeletePart,
  getTripReports, postTripReport, patchTripReport, deleteTripReport as remotingDeleteTripReport, getTripUploadPath,
} from '../remoting/';

import {
  addError,
  setAvailTenants, setAvailTenant, setCurrentTenantId,
  setSystems, setSystem, removeSystem,
  setModule, removeModule,
  setUsers as reduxSetUsers, setUser as reduxSetUser, removeUser as reduxRemoveUser,
  setPosition, removePosition,
  setUiBusy,
  setTripReports,
} from './actions';

// Thunk action creators can return a function!
// Clean way to isolate async redux outside of front end code

// Example below for App.jsx

/**
 * Async
 */
export const initTenants = () => async (dispatch, getState) => {
  const tenantObj = {};

  const { awsUser } = getState();

  if (awsUser) {
    const fetchedTenants = await fetchAvailTenants()(dispatch);

    if (fetchedTenants) {
      fetchedTenants.forEach((currentTenant, i) => {
        tenantObj[currentTenant.tenant_id] = currentTenant;
      });
    }
    await dispatch(setAvailTenants(tenantObj));
  } else {
    await dispatch(setAvailTenants({}));
  }
};

export const updateCurrentTenantId = (newTenantId) =>  async (dispatch, getState) => {
  const systemsObj = {};
  let tripReports = null;

  if (newTenantId) {
    const [systemsArray, tripsArray] = await Promise.all([
      fetchSystemsByTenantId(newTenantId)(dispatch),
      getTripReports(newTenantId)(dispatch),
    ]);


    if (systemsArray) {
      systemsArray.forEach(currentSystem => {
        systemsObj[currentSystem.system_id] = currentSystem;
      });
    }

    if (tripsArray) {
      tripReports = tripsArray.sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime());
    }
  }

  await Promise.all([
    dispatch(setCurrentTenantId(newTenantId)),
    dispatch(setSystems(systemsObj)),
    dispatch(setTripReports(tripReports)),
  ]);
};

export const putUpdatedTenant = (updatedTenant) => async (dispatch, getState) => {
  const returnedTenant = await patchTenant(updatedTenant)(dispatch);
  if (returnedTenant) {
    dispatch(setAvailTenant(returnedTenant));
  }
}

export const setActiveTenant = (tenantId) => async (dispatch, getState) => {
  const { currentTenantId, tenants } = getState();

  if (currentTenantId === tenantId) return;

  // Sanity check
  const tenant = tenants[tenantId];
  if (!tenant) throw Error('setTenantById called for unknown tenant', tenantId);

  // Don't fetch systems if already fetched!
  // TODO: What if the API just had a cached response instead?
  if (!!tenant.systems) {
    dispatch(wrappedSetCurrentTenant(tenantId));
    return;
  }

  dispatch(setSystemsByTenant(tenant));
  return;
}

// To remove
export const wrappedSetCurrentTenant = (tenantId) => (dispatch, getState) => {
  const { availTenants } = getState();
  const tenantIndex = availTenants.findIndex(t => t.tenant_id === tenantId);
  if (tenantIndex < 0) throw Error(`Tenant is missing from availTenants: ${tenantId}`);
  dispatch(setCurrentTenantId(tenantId));
}

/**
 * Async
 * @saga must follow setAvailableTenants
 */
export const setSystemsByTenant = (tenant) => async (dispatch, getState) => {
  const { systems, tenants } = getState();
  const newSystemsArray = await fetchSystemsByTenantId(tenant.tenant_id)(dispatch) || [];

  // TODO: remove old array index functionality
  dispatch(
    setSystems(newSystemsArray.reduce(
      (a, currentSystem) => {
        const { system_id: systemId } = currentSystem;
        a.systems[systemId] = currentSystem;
        a.tenants[tenant.tenant_id].systems.push(systemId);
        return a;
      },
      {
        systems,
        tenants: {
          ...tenants,
          [tenant.tenant_id]: {
            ...tenant,
            systems: [],
          }
        },
      },
    ))
  );
  dispatch(wrappedSetCurrentTenant(tenant.tenant_id));
};

export const getUsers = () => async (dispatch, getState) => {
  const fetchedUsers = await remotingGetUsers()(dispatch);

  dispatch(reduxSetUsers(fetchedUsers));
  return fetchedUsers;
};

export const addUser = (newUser) => async (dispatch, getState) => {
  const returnedUser = await postUser(newUser)(dispatch);
  if (returnedUser) {
    await dispatch(reduxSetUser(returnedUser));
  }
};

export const updateUser = (updatedUser) => async (dispatch, getState) => {
  const returnedUser = await patchUser(updatedUser)(dispatch);
  if (returnedUser) {
    dispatch(reduxSetUser(returnedUser));
  }
};

export const deleteUser = (deleteUserId) => async (dispatch, getState) => {
  const deleted = await remotingDeleteUser(deleteUserId)(dispatch);
  if (deleted) {
    await dispatch(reduxRemoveUser(deleteUserId));
  }
};

export const addSystem = (newSystem) => async (dispatch, getState) => {
  const returnedSystem = await postSystem(newSystem)(dispatch);
  if (returnedSystem) {
    dispatch(setSystem(returnedSystem));
  }
  return returnedSystem;
};

export const updateSystem = (newSystem) => async (dispatch, getState) => {
  await patchSystem(newSystem)(dispatch);
  const postPatchSystem = await fetchSystemById(newSystem.tenant_id, newSystem.system_id)(dispatch);
  if (postPatchSystem) {
    dispatch(setSystem(postPatchSystem));
  }
  return postPatchSystem;
}

export const deleteSystem = (tenant_id, system_id) => async (dispatch, getState) => {
  const deleted = await remotingDeleteSystem(tenant_id, system_id)(dispatch);
  if (deleted) {
    dispatch(removeSystem(system_id));
  }
}

export const uploadDocument = (tenant_id, system_id, docname, newFile) => async (dispatch, getState) => {
  const newFileName = encodeURIComponent(`${system_id}/${newFile.name}`);
  const uploadPath = await getDocUploadPath(tenant_id, newFileName)(dispatch);
  if (uploadPath) {
    await fetch(
      uploadPath.put_url,
      {
        headers: uploadPath.headers,
        method: 'PUT',
        body: newFile,
      }
    );
  }

  return uploadPath;
};

export const uploadTripReport = (tenant_id, tripReportFile) => async (dispatch, getState) => {
  const newFilename = encodeURIComponent(tripReportFile.name);
  const uploadPath = await getTripUploadPath(tenant_id, newFilename)(dispatch);
  if (uploadPath) {
    await fetch(
      uploadPath.put_url,
      {
        headers: uploadPath.headers,
        method: 'PUT',
        body: tripReportFile,
      }
    );
  }

  return uploadPath;
};

export const addTripReport = (tenant_id, newTripReport) => async (dispatch, getState) => {
  const postedTripReport = await postTripReport(tenant_id, newTripReport)(dispatch);
  if (postedTripReport) {
    const { tripReports } = getState();
    const newTripReports = [
      ...tripReports,
      postedTripReport
    ]
    .sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime());
    dispatch(setTripReports(newTripReports));
  }
};

export const updateTripReport = (tenant_id, trip_report_id, tripReport) => async (dispatch, getState) => {
  const patchedTripReport = await patchTripReport(tenant_id, trip_report_id, tripReport)(dispatch);
  if (patchedTripReport) {
    const { tripReports } = getState();
    let newTripReports = [...tripReports];
    const currentTripIndex = newTripReports.findIndex(currentTripReport => currentTripReport.trip_report_id === patchedTripReport.trip_report_id);
    newTripReports[currentTripIndex] = patchedTripReport;
    newTripReports = newTripReports.sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime());
    dispatch(setTripReports(newTripReports));
  }
};

export const deleteTripReport = (tenant_id, trip_report_id) => async (dispatch, getState) => {
  const deleted = await remotingDeleteTripReport(tenant_id, trip_report_id)(dispatch);
  if (deleted) {
    const { tripReports } = getState();
    const newTripReports = tripReports.filter(currentTripReport => currentTripReport.trip_report_id !== trip_report_id);
    dispatch(setTripReports(newTripReports));
  }
};

export const addSubsystem = (newSubsystem) => async (dispatch, getState) => {
  const returnedSubsystem = await postSubsystem(newSubsystem)(dispatch);

  if (returnedSubsystem) {
    const updatedSystem = await fetchSystemById(newSubsystem.tenant_id, newSubsystem.system_id)(dispatch);
    if (updatedSystem) {
      dispatch(setSystem(updatedSystem));
    }
  }
  return returnedSubsystem;
};

export const updateSubsystem = (newSubsystem) => async (dispatch, getState) => {
  const returnedSubsystem = await patchSubsystem(newSubsystem)(dispatch);
  if (returnedSubsystem) {
    const updatedSystem = await fetchSystemById(newSubsystem.tenant_id, newSubsystem.system_id)(dispatch);
    if (updatedSystem) {
      dispatch(setSystem(updatedSystem));
    }
  }

  return returnedSubsystem;
};

export const deleteSubsystem = (tenantId, systemId, subsystemId) => async (dispatch, getState) => {
  const deleted = await remotingDeleteSubsystem(tenantId, subsystemId)(dispatch);
  if (deleted) {
    const updatedSystem = await fetchSystemById(tenantId, systemId)(dispatch);
    dispatch(setSystem(updatedSystem));
  }
};

export const addModule = (tenantId, newModule) => async (dispatch, getState) => {
  const returnedModule = await postModule(tenantId, newModule)(dispatch);
  if (returnedModule) {
    dispatch(setModule(returnedModule));
  }
  return returnedModule;
};

export const updateModule = (newModule) => async (dispatch, getState) => {
  const returnedModule = await patchModule(newModule)(dispatch);
  if (returnedModule) {
    dispatch(setModule(returnedModule));
  }
  return returnedModule;
};

export const deleteModule = (oldModule) => async (dispatch, getState) => {
  const deleted = await remotingDeleteModule(oldModule.tenant_id, oldModule.module_id)(dispatch);
  if (deleted) {
    dispatch(removeModule(oldModule.module_id));
  }
};

export const addPosition = (tenantId, newPosition) => async (dispatch, getState) => {
  const postedPosition = await postPosition(tenantId, newPosition)(dispatch);
  if (postedPosition) {
    dispatch(setPosition(postedPosition));
  }
  return postedPosition;
};

export const updatePosition = (newPosition) => async (dispatch, getState) => {
  const patchedPosition = await patchPosition(newPosition.tenant_id, newPosition.position_id, newPosition)(dispatch);
  if (patchedPosition) {
    dispatch(setPosition(patchedPosition));
  }
  return patchedPosition;
};

export const deletePosition = (oldPosition) => async (dispatch, getState) => {
  const deleted = await remotingDeletePosition(oldPosition.tenant_id, oldPosition.position_id)(dispatch);
  if (deleted) {
    dispatch(removePosition(oldPosition.position_id));
  }
};

export const addPart = (tenantId, newPart) => async (dispatch, getState) => {
  const postedPart = await postPart(tenantId, newPart)(dispatch);
  if (postedPart) {
    const { systems, currentSystemId } = getState();
    const currentSystem = systems[currentSystemId];
    const updatedSystem = {
      ...currentSystem,
      parts: currentSystem.parts
        ? [
         ...currentSystem.parts,
         postedPart,
        ]
        : [postedPart]
    };
    dispatch(setSystem(updatedSystem));
  }
  return postedPart;
};

export const updatePart = (newPart) => async (dispatch, getState) => {
  const patchedPart = await patchPart(newPart)(dispatch);
  if (patchedPart) {
    const {systems, currentSystemId } = getState();
    const currentSystem = systems[currentSystemId];
    const updatedParts = currentSystem.parts.map(currentPart => {
      if (currentPart.part_id === patchedPart.part_id) {
        return patchedPart;
      }
      return currentPart;
    })
    const updatedSystem = {
      ...currentSystem,
      parts: updatedParts,
    }
    dispatch(setSystem(updatedSystem));
  }
}

export const deletePart = (deletePart) => async (dispatch, getState) => {
  const deleted = await remotingDeletePart(deletePart.tenant_id, deletePart.part_id)(dispatch);
  if (deleted) {
    const { systems, currentSystemId } = getState();
    const currentSystem = systems[currentSystemId];
    const updatedParts = currentSystem.parts.filter(currentPart => currentPart.part_id !== deletePart.part_id);
    const updatedSystem = {
      ...currentSystem,
      parts: updatedParts,
    }
    dispatch(setSystem(updatedSystem));
  }
};

const wait = async (waitTime) => {
  await (new Promise((resolve, reject) => {
    setTimeout(() => resolve(), waitTime);
  }));
};

export const incUiBusy = () => async (dispatch, getState) => {
  const { uiBusy } = getState();

  if ( uiBusy === 'ok') {
    await dispatch(setUiBusy(1));
  } else {
    await dispatch(setUiBusy(uiBusy + 1));
  }
};

export const decUiBusy = () => async (dispatch, getState) => {
  let { uiBusy } = getState();
  if (uiBusy > 1) {
    await dispatch(setUiBusy(uiBusy - 1));
  } else if (uiBusy === 1) {
    await dispatch(setUiBusy('ok'));
    await wait(750);
    uiBusy = getState().uiBusy;
    if (uiBusy === 'ok') {
      await dispatch(setUiBusy(0));
    }
  }
};

/**
 * Utility to wrap event handlers with a try/catch block and display any errors.
 *
 * For example:
 *
 * <Button onClick={withCatch((evt) => { stuff.that.could.fail(); })}>...</Button>
 */
export const withCatch = (callback) => (dispatch) => async (...args) => {
  try {
    await callback(...args);
  }
  catch (err) {
    dispatch(addError(err));
  }
};

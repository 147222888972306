export function setUiBusy(newUiState) {
  return {
    type: 'SET_UI_BUSY',
    data: newUiState,
  };
}

export function setAvailTenants(newAvailTenants) {
  return {
    type: 'SET_AVAIL_TENANTS',
    data: newAvailTenants,
  };
}

export function setAvailTenant(newTenant) {
  return {
    type: 'SET_AVAIL_TENANT',
    data: newTenant,
  };
}

export function removeAvailTenant(tenantId) {
  return {
    type: 'REMOVE_AVAIL_TENANT',
    data: tenantId,
  };
}

export function setSystems(newSystems) {
  return {
    type: 'SET_SYSTEMS',
    data: newSystems
  };
}

export function setSystem(newSystem) {
  return {
    type: 'SET_SYSTEM',
    data: newSystem,
  };
}

export function removeSystem(systemId) {
  return {
    type: 'REMOVE_SYSTEM',
    data: systemId,
  };
}

export function updateCurrentSystemId(newSystemId) {
  return {
    type: 'UPDATE_CURRENT_SYSTEM',
    data: newSystemId,
  };
}

export function setCurrentTenantId(currentTenantId) {
  return {
    currentTenantId,
    type: 'SET_CURRENT_TENANT',
  };
}

export function setAwsUser(awsUser) {
  return {
    type: 'SET_AWS_USER',
    data: awsUser,
  };
}

export function setUsers(newUsers) {
  return {
    type: 'SET_USERS',
    data: newUsers,
  };
}

export function setUser(newUser) {
  return {
    type: 'SET_USER',
    data: newUser,
  };
}

export function removeUser(removedUserId) {
  return {
    type: 'REMOVE_USER',
    data: removedUserId,
  };
}

export function setCurrentSubsystemId(newSubsystemId) {
  return {
    type: 'SET_CURRENT_SUBSYSTEM_ID',
    data: newSubsystemId,
  };
}

export function setCurrentModuleId(newModuleId) {
  return {
    type: 'SET_CURRENT_MODULE_ID',
    data: newModuleId,
  };
}

export function setModules(newModules) {
  return {
    type: 'SET_MODULES',
    data: newModules,
  };
}

export function setModule(newModule) {
  return {
    type: 'SET_MODULE',
    data: newModule,
  };
}

export function removeModule(oldModuleId) {
  return {
    type: 'REMOVE_MODULE',
    data: oldModuleId,
  };
}

export function setPositions(newPositions) {
  return {
    type: 'SET_POSITIONS',
    data: newPositions,
  };
}

export function setPosition(newPosition) {
  return {
    type: 'SET_POSITION',
    data: newPosition,
  };
}

export function removePosition(removePositionId) {
  return {
    type: 'REMOVE_POSITION',
    data: removePositionId,
  };
}

export function setTripReports(newTripReports) {
  return {
    type: 'SET_TRIP_REPORTS',
    data: newTripReports,
  };
}

// Record a new error
export const addError = (error) => {
  // Start with the basic item
  const item = {
    text: null,
    title: null,
    error
  };

  if (!error) {
    item.text = 'Unknown error';
  }
  else if (typeof error === 'string') {
    // Just use the text
    item.text = error;
  }
  else if (error.response) {
    // API error
    const status = error.response.status;

    item.title = `API ERROR [${status}]`;

    // Fallback to a generic (and useless) message
    item.text = 'Server error';
    if (error.response.data?.detail) {
      // The API has provided an error message
      const detail = error.response.data?.detail
      if (typeof detail === 'string') {
        // Simple message
        item.text = detail;
      }
      else if ((status === 422) && Array.isArray(detail)) {
        // Validation errors are sent as arrays
        item.text = 'Validation Error:\n' + detail.map(item => `\t${item.msg}: ${item.loc.join('.')}`).join('\n');
      }
      else {
        // Not sure what this is, but let's make it at least sort of readable and not '[Object object]'
        item.text = JSON.stringify(detail);
      }
    }
  }
  else if (typeof error.message === 'string') {
    // Get the Error message
    item.text = error.message;
  }
  else {
    // Hmm, something else
    item.text = error.toString();
  }

  return {
    type:  'ERRORS_ADD',
    error: item,
  };
};

// Dismiss the current error
export const dismissError = () => ({
  type: 'ERRORS_REMOVE'
});

// Clear all errors
export const clearErrors = () => ({
  type: 'ERRORS_CLEAR',
});

export const updateOffsetHeight = (newHeight) => ({
  type: 'UPDATE_OFFSET_HEIGHT',
  data: newHeight,
});

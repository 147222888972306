/**
 * User Sign Form
 */
import React from 'react';

import {
  AmplifyAuthenticator,
  AmplifySignIn,
} from '@aws-amplify/ui-react';

import { withStyles } from '@material-ui/core/styles';

import kssLogo from '../images/signInLogo.png';

const styles = (theme) => ({
  logo: {
    display: 'block',
    margin: theme.spacing(2),
    height: 'auto',
    maxHeight: '25vh',
    maxWidth: '100%',
  },
  container: {
    minHeight: '100vh',
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
});

class SignIn extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <AmplifyAuthenticator>
          <AmplifySignIn slot="sign-in" hideSignUp={true} />
        </AmplifyAuthenticator>
        <img src={kssLogo} alt="KSS Logo" className={classes.logo} />
      </div>
    );
  }
}

export default withStyles(styles)(SignIn)
